@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

.ql-editor strong {
  font-weight: bold;
}
.ql-editor em {
  font-style: italic;
}
.ql-editor sup {
  vertical-align: super;
}
.ql-editor sub {
  vertical-align: sub;
}

.imageUpload {
  display: block;
  margin: 0 auto;
}
/* FONT FAMILY */
#toolbar-container .ql-font span[data-label='Sans Serif']::before {
  font-family: 'Sans Serif';
}

#toolbar-container .ql-font span[data-label='Inconsolata']::before {
  font-family: 'Inconsolata';
}

#toolbar-container .ql-font span[data-label='Roboto']::before {
  font-family: 'Roboto';
}

#toolbar-container .ql-font span[data-label='Inter']::before {
  font-family: 'Inter';
}

#toolbar-container .ql-font span[data-label='Arial']::before {
  font-family: 'Arial';
}

#toolbar-container .ql-font span[data-label='Lato']::before {
  font-family: 'Lato';
}

#toolbar-container .ql-font span[data-label='Poppins']::before {
  font-family: 'Poppins';
}
/* Set content font-families */

.ql-font-inconsolata {
  font-family: 'Inconsolata';
}

.ql-font-roboto {
  font-family: 'Roboto';
}

.ql-font-inter {
  font-family: 'Inter';
}

.ql-font-arial {
  font-family: 'Arial';
}

.ql-font-lato {
  font-family: 'Lato';
}

.ql-font-poppins {
  font-family: 'Poppins';
}
